import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import { FaUser, FaPhone, FaEnvelope, FaBuilding, FaMapMarkerAlt, FaPenAlt } from 'react-icons/fa'
import {useDispatch, useSelector} from "react-redux"

import {
    memberToForm,
    onChange,
    onChangeByName,
    submitInformation,
    userToForm
} from "../../redux/media/mediaregistrationAction"

import {
    submitDocuments,
    userToDocuments,
} from "../../redux/registration/registrationAction"
import Upload from "../singleInformation/documents/upload"

import CountriesInput from "../../components/form/countriesInput"
import PhoneInput from "../../components/form/phoneInput"
import ProfessionalInput from "../../components/form/freeprofessionalInput"
import CheckboxInput from "./welcome/checkboxInput"
import HelpUr from "../../components/help/help"
import {isDefined} from "../../utils/data"

const About = ({prevTab, nextTab}) => {
    const dispatch      = useDispatch()
    const {information} = useSelector(({mediaregistrationReducer}) => mediaregistrationReducer)
    const {categories, category, user, member}  = useSelector(({apiReducer}) => apiReducer)
    const localCategories = ["Physician", "Researcher", "Public Health worker", "Student", "Nurse", "Employee", "Other"]
    
    const disabledInput = isDefined(user)

    useEffect(() => {
        if(user !== null) {
            dispatch(userToForm(user))

             if(localCategories.filter(ctg => ctg !== "Other").indexOf(user.registered.profession_category) === -1) {
                dispatch(onChangeByName("registered_profession_category", user.registered.profession_category))
                dispatch(onChangeByName("professionalCtgSelect", "Other"))
            }
            else {
                dispatch(onChangeByName("registered_profession_category", user.registered.profession_category))
            }
        }
    }, [user])
    
    useEffect(() => {
        if(member !== null) {
            dispatch(memberToForm(member))
        }
    }, [member])

    const onSubmit = (e = null) => {
        e.preventDefault()
        const form = e.currentTarget

        if (form.checkValidity() === false) {
            e.stopPropagation()
        }

      dispatch(onChangeByName("media", "1"))
      dispatch(onChangeByName("registered_category", "33"))
      dispatch(submitInformation(user, information, categories, category, nextTab))
    }

    return (
        <div className={"content about"}>
            <p className={"title"}>
                Media registration
            </p>
        <p className={"media_text"}>Please note that the Union World Conference cannot accept applications for media credentials from representatives of media outlets that are not directly involved in journalism.</p>
        <p className={"media_text"}>In order to apply for complimentary media registration, you much be a journalist or videographer who is employed by or on assignment for a recognised print, radio, broadcast or online consumer, medical, community-based or lung health service organization-sponsored media outlet (tuberculosis, respiratory, tobacco control, asthma, air pollution) are eligible to.</p>
        <p className={"media_text"}>Applications are also accepted from community educators. This refers to anyone doing journalism for lung health service organisations or other community-based media outletsPlease note that the Union World Conference cannot accept applications for media credentials from representatives of media outlets that are not directly involved in journalism.</p>
  

            <form onSubmit={onSubmit} className={"needs-validation"} noValidate>
                <div className="form-group">
                    <div className="form-row">
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaUser/></label>
                            <select className="form-control" name={"registered_gender"} value={information.registered_gender.value} onChange={e => dispatch(onChange(e))} >
                                <option value="" disabled selected>Title</option>
                                <option>Mr</option>
                                <option>Ms</option>
                                <option>Mx</option>
                                <option>Dr</option>
                                <option>Prof</option>
                            </select>
                            {information.registered_gender.error !== "" ?
                                <div className="invalid-feedback">{information.registered_gender.error}</div>
                            : ""}
                        </div>
                    </div>
                    <div className="form-row">
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaUser/></label>

                            <input type="text" className="form-control" placeholder="Firstname *" name={"registered_firstname"} value={information.registered_firstname.value} onChange={e => dispatch(onChange(e))} disabled={disabledInput} required/>
                        {information.registered_firstname.error !== "" ?
                            <div className="invalid-feedback">{information.registered_firstname.error}</div>
                            : ""}
                        </div>
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaUser/></label>
                            <input type="text" className="form-control" placeholder="Lastname *" name={"registered_lastname"} value={information.registered_lastname.value} onChange={e => dispatch(onChange(e))} disabled={disabledInput} required/>
                        {information.registered_lastname.error !== "" ?
                            <div className="invalid-feedback">{information.registered_lastname.error}</div>
                        : ""}
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="form-row">
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaMapMarkerAlt/></label>
                            <input type="text" className="form-control" placeholder="Address *" name={"registered_adress1"} value={information.registered_adress1.value} onChange={e => dispatch(onChange(e))} required/>
                        {information.registered_adress1.error !== "" ?
                            <div className="invalid-tooltip">{information.registered_adress1.error}</div>
                            : ""}
                        </div>
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaMapMarkerAlt/></label>
                            <input type="text" className="form-control" placeholder="Additional address" name={"registered_adress2"} value={information.registered_adress2.value} onChange={e => dispatch(onChange(e))} required={information.registered_adress2.required}/>
                            {information.registered_adress2.error !== "" ?
                                <div className="invalid-tooltip">{information.registered_adress2.error}</div>
                            : ""}
                        </div>
                        <div className={"col-12 col-md-4 col-lg-4 input-group"}>
                            <label><FaMapMarkerAlt/></label>
                            <input type="text" className="form-control" placeholder="Postal Code *" name={"registered_zipcode"} value={information.registered_zipcode.value} onChange={e => dispatch(onChange(e))} required/>
                            {information.registered_zipcode.error !== "" ?
                                <div className="invalid-tooltip">{information.registered_zipcode.error}</div>
                            : ""}
                        </div>
                        <div className={"col-12 col-md-4 col-lg-4 input-group"}>
                            <label><FaMapMarkerAlt/></label>
                            <input type="text" className="form-control" placeholder="City *" name={"registered_city"} value={information.registered_city.value} onChange={e => dispatch(onChange(e))} required/>
                            {information.registered_city.error !== "" ?
                                <div className="invalid-tooltip">{information.registered_city.error}</div>
                            : ""}
                        </div>
                        <div className={"col-12 col-md-4 input-group"}>
                            <label><FaMapMarkerAlt/></label>
                                <CountriesInput value={information.registered_country.value} onChange={e => dispatch(onChange(e))} disabled={disabledInput}/>
                            {information.registered_country.error !== "" ?
                                <div className="invalid-tooltip">{information.registered_country.error}</div>
                            : ""}
                        </div>

                    </div>
                </div>
                            
                <div className="form-group">
                    <div className="form-row">
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaEnvelope/></label>
                            <input type="email" className="form-control" placeholder="Email *" name={"registered_mail"} value={information.registered_mail.value} onChange={e => dispatch(onChange(e))} disabled={disabledInput} required/>
                            {information.registered_mail.error !== "" ?
                                <div className="invalid-tooltip">{information.registered_mail.error}</div>
                            : ""}
                        </div>
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaEnvelope/></label>
                            <input type="email" className="form-control" placeholder="Confirm Email *" name={"confirmEmail"} value={information.confirmEmail.value} onChange={e => dispatch(onChange(e))} disabled={disabledInput} required/>
                            {information.confirmEmail.error !== "" ?
                                <div className="invalid-tooltip">{information.confirmEmail.error}</div>
                            : ""}
                        </div>
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaPhone/></label>
                            <PhoneInput value={information.registered_phone.value} onChange={e => dispatch(onChange(e))}/>
                            {information.registered_phone.error !== "" ?
                                <div className="invalid-tooltip">{information.registered_phone.error}</div>
                            : ""}
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="form-row">
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaBuilding/></label>
                            <input type="text" className="form-control" placeholder="Media Outlet *" name={"registered_organisation"} value={information.registered_organisation.value} onChange={e => dispatch(onChange(e))} />
                            {information.registered_organisation.error !== "" ?
                                <div className="invalid-tooltip">{information.registered_organisation.error}</div>
                            : ""}
                        </div>
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaPenAlt/></label>
                            <input type="text" className="form-control" placeholder="Current Position" name={"registered_function"} value={information.registered_function.value} onChange={e => dispatch(onChange(e))} />
                            {information.registered_function.error !== "" ?
                                <div className="invalid-tooltip">{information.registered_function.error}</div>
                            : ""}
                        </div>
</div>
                </div>
                <div className={"form-row"}>
                    <div className={"col-12 mt-3"}>
                      <p className={"media_text"}>Please upload ONE document, containing 3 recent samples of work and a commissioning letter from your editor/producer.</p>
                        {information.registered_function.error !== "" ?
                                <div className="invalid-tooltip">{information.registered_file.error}</div>
                            : ""}
                        <Upload/>
                    </div>
                </div>

                <div className={"content-footer"}>
                    <a className={"sr-btn-next btn btn-secondary"} href={"https://union2020.urevent.fr/"}>Previous</a>
                    <small  className={"help"}><HelpUr/></small>
                    <button type={"submit"} className={"sr-btn-next btn btn-info"}>Next</button>
                </div>
            </form>
        </div>
    )
}

About.propTypes = {
    prevTab: PropTypes.func.isRequired,
    nextTab: PropTypes.func.isRequired,
}

export default About
