import React, {useEffect, useState} from "react"
import PropTypes from 'prop-types'
import {Link} from "gatsby"
import {useDispatch, useSelector} from "react-redux"

import "./information.scss"
import {recordUser} from "../../redux/api/apiAction"
import CheckboxInput from "./welcome/checkboxInput"
import {validTab} from "../../redux/tabs/tabsAction"
import HelpUr from "../../components/help/help"
import {onChangeInformation, onChange} from "../../redux/registration/registrationAction"

const Information = ({prevTab, nextTab}) => {
    const dispatch      = useDispatch()
    const {information} = useSelector(({registrationReducer}) => registrationReducer)
    const {registered_interest, registered_intouch} = information
    const checkboxTermsAndConditionsMessage = "Please check privacy";
    const {user}    = useSelector(({apiReducer}) => apiReducer)
    const data      = useSelector(({registrationReducer}) => registrationReducer)
    const {stripe}  = useSelector(({registrationReducer}) => registrationReducer)

    const onSubmit = (e = null) => {
        const form = e.currentTarget
        
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
        }

        if(registered_interest.value.length > 0 && (information.registered_cvg.value === true || information.registered_cvg.value === "1")) {
                dispatch(recordUser(user, data, 0))
        }   
    }

    const getValue = (name, isInterest = true) => {
        if(isInterest)
            return registered_interest.value.indexOf(name) !== -1
        else
            return registered_intouch.value.indexOf(name) !== -1
    }
    
    return (
        <div className={"content information"}>

            <form onSubmit={onSubmit} className={"needs-validation"} noValidate>
                <div className="form-group">
                    <div className="form-row">
                        <div className={"col-12"}>
                            <p className={"title"}>Main areas of interest *</p>

                            <div className="form-row">
                                <div className={"col-12 col-lg-6"}>
                                    <CheckboxInput placeholder={"Immunology"} onChange={e => dispatch(onChangeInformation(e))} name={"Immunology"} value={getValue("Immunology")}/>
                                    <CheckboxInput placeholder={"Drug and vaccine development"} onChange={e => dispatch(onChangeInformation(e))} name={"Drug and vaccine development"} value={getValue("Drug and vaccine development")}/>
                                    <CheckboxInput placeholder={"Diagnostics"} onChange={e => dispatch(onChangeInformation(e))} name={"Diagnostics"} value={getValue("Diagnostics")}/>
                                    <CheckboxInput placeholder={"Clinical Research"} onChange={e => dispatch(onChangeInformation(e))} name={"Clinical Research"} value={getValue("Clinical Research")}/>
                                    <CheckboxInput placeholder={"Clinical Care"} onChange={e => dispatch(onChangeInformation(e))} name={"Clinical Care"} value={getValue("Clinical Care")}/>
                                    <CheckboxInput placeholder={"Laboratory services"} onChange={e => dispatch(onChangeInformation(e))} name={"Laboratory services"} value={getValue("Laboratory services")}/>
                                </div>
                                <div className={"col-12 col-lg-6"}>
                                    <CheckboxInput placeholder={"Paediatrics"} onChange={e => dispatch(onChangeInformation(e))} name={"Paediatrics"} value={getValue("Paediatrics")}/>
                                    <CheckboxInput placeholder={"Epidemiology"} onChange={e => dispatch(onChangeInformation(e))} name={"Epidemiology"} value={getValue("Epidemiology")}/>
                                    <CheckboxInput placeholder={"HIV and other comorbidities"} onChange={e => dispatch(onChangeInformation(e))} name={"HIV and other comorbidities"} value={getValue("HIV and other comorbidities")}/>
                                    <CheckboxInput placeholder={"Tobacco control"} onChange={e => dispatch(onChangeInformation(e))} name={"Tobacco control"} value={getValue("Tobacco control")}/>
                                    <CheckboxInput placeholder={"Civil Society and Human rights"} onChange={e => dispatch(onChangeInformation(e))} name={"Civil Society and Human rights"} value={getValue("Civil Society and Human rights")}/>
                                    <CheckboxInput placeholder={"COVID 19"} onChange={e => dispatch(onChangeInformation(e))} name={"COVID 19"} value={getValue("COVID 19")}/>
                                </div>
                            </div>
                            {information.registered_interest.error !== "" ?
                                <div className="invalid-feedback">{information.registered_interest.error}</div>
                            : ""}
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className={"form-row"}>
                        <div className={"col-12"}>
                            <CheckboxInput placeholder={"I would like to hear The Union’s work and the latest news, research and vital information on lung health"} 
                                onChange={e => dispatch(onChangeInformation(e, false))} 
                                name={"accept_com"} 
                                value={getValue("accept_com", false)}/>
       
                        </div>
                    </div>
                    <div className={"form-row"}>
                        <div className={"col-12"}>
                            <CheckboxInput placeholder={"I understand that by creating an account, I agree to"}
                           onChange={e => dispatch(onChange(e, true))}
                           name={"registered_cvg"}
                           value={information.registered_cvg.value}
                           required={true}/>
                        </div>
                    </div>
                            {information.registered_cvg.error !== "" ?
                                <div className="invalid-feedback">{information.registered_cvg.error}</div>
                            : ""}
                </div>
            </form>


            <div className={"content-footer"}>
                <button className={"sr-btn-next btn btn-secondary"} onClick={prevTab}>Previous</button>
                    <small  className={"help"}><HelpUr/></small>
                <Link to={"confirmation-free-record"}>
                    <button className={"sr-btn-next btn btn-warning"} disabled={!(registered_interest.value.length > 0 && (information.registered_cvg.value === true || information.registered_cvg.value === "1"))}  onClick={onSubmit}>SAVE REGISTRATION </button>
                </Link>
            </div>
        </div>
    )
}

Information.propTypes = {
    prevTab: PropTypes.func.isRequired,
    nextTab: PropTypes.func.isRequired,
}

export default Information
